<template>
    <v-container fluid>
        <v-form @submit.prevent="saveContainerTerminal">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'container_terminal'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="container_terminal" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="container_terminal" type="text" :disabled="loading"
                                                  :error="!valid" :error-messages="errors"
                                                  prepend-icon="mdi-check"
                                                  :label="$t('container_terminal_name')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";

    export default {
        name: 'ContainerTerminalForm',
        components: {
            ValidationProvider,
            ValidationObserver
        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                container_terminal: null,
                exist_translations: {},
                all_translations: true,
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.checkCreate()
        },
        methods: {
            checkCreate() {
                if (this.$route.name === "container_terminal.create") {
                    this.heading = this.$t('container_terminal_creation')
                } else {
                    this.heading = this.$t('container_terminal_editing')
                    if (this.$route.params.id) {
                        this.getContainerTerminal()
                    }
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            async getContainerTerminal() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/container_terminal/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.container_terminal = res.body.data.name
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_container_terminal'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveContainerTerminal() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.container_terminal) {
                    formData.append('container_terminal', this.container_terminal)
                }
                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/container_terminal/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('container_terminal_has_been_updated'))
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('container_terminal_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/container_terminal', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('container_terminal_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'container_terminal.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'container_terminal'
                                })
                            }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('container_terminal_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
